<template>
	<div>
		<div class="d-flex">
			<div style="width: 9%">
				<button style="background-color: transparent; border: none" class="float-left" @click="handleClickPrevDate">
					<b-icon icon="chevron-left" style="margin-top: 6px" font-scale="1.5" />
				</button>
			</div>
			<div style="width: 82%" class="text-center">
				<span class="label-calendar">{{ currDateStr }}</span>
			</div>
			<div style="width: 9%">
				<button style="background-color: transparent; border: none" class="float-right" @click="handleClickNextDate">
					<b-icon icon="chevron-right" style="margin-top: 6px" font-scale="1.5" />
				</button>
			</div>
		</div>
		<b-row v-if="showStatus && !isNew" class="mt-1">
			<b-col>
				<div class="wrap-status d-flex justify-content-cnter" v-if="!isFilmSingle">
					<div :class="`status ${curDay.statusClass}`" style="font-size: 0.9rem">
						{{ curDay.validatedStatus }}
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import moment from 'moment';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import { getLangISOByIndex } from '../../helpers/translate';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';

export default {
	name: 'CalendarWork',
	mixins: [languageMessages, isSingleProjectMixin],
	props: {
		curDay: { type: Object, required: true },
		showStatus: { type: Boolean, required: true }
	},
	data() {
		const date = new Date();
		return {
			//currDateStr: moment(date).format('dddd, D MMMM'),
			currentDate: date,
			currentMonth: date.getMonth() + 1,
			listValidatedMenu: []
		};
	},
	mounted() {
		moment.locale(getLangISOByIndex(store.appLanguage()).two);
	},
	computed: {
		isNew() {
			return parseInt(this.curDay.id) === 0;
		},
		currDateStr: {
			get() {
				return moment(this.currentDate).format('dddd, D MMMM YYYY');
			}
		},
		showLabelToday() {
			let curDate = new Date(this.curDay.date);
			curDate = moment(curDate).format('YYYY-MM-DD');
			let date = new Date();
			date = moment(date).format('YYYY-MM-DD');
			if (curDate === date) {
				return true;
			}
			return false;
		}
	},

	watch: {
		curDay: {
			handler(val) {
				const date = new Date(val.date);
				this.currentDate = date;
				this.currentMonth = date.getMonth() + 1;
			},
			immediate: true,
			deep: true
		}
	},

	methods: {
		validatedText(validated) {
			return getTextFromMenuNumberAndMenuValue(1008, validated);
		},
		handleClickPrevDate() {
			this.currentDate = moment(this.currentDate).subtract(1, 'days');
			this.emitEvent();
		},
		handleClickNextDate() {
			this.currentDate = moment(this.currentDate).add(1, 'days');
			this.emitEvent();
		},
		emitEvent() {
			const date = new Date(this.currentDate);
			let month = parseInt(date.getMonth(), 10) + 1;
			let day = parseInt(date.getDate(), 10);

			if (month < 10) {
				month = '0' + month;
			}
			if (day < 10) {
				day = '0' + day;
			}

			if (this.currentMonth !== date.getMonth() + 1) {
				this.$emit('calendar-work:from-page', {
					month: date.getMonth() + 1,
					year: date.getFullYear(),
					isCalendarWork: true
				});

				this.currentMonth = date.getMonth() + 1;
			}

			this.$emit('calendar-work:change-date', {
				id: date.getFullYear() + '-' + month + '-' + day,
				date: this.currentDate,
				isCalendarWork: true
			});
		}
	}
};
</script>

<style scoped></style>
